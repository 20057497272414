import React from "react";
import "./Surrounding.scss";
import aeon from "../../asset/Surrounding/universitas.webp";
import prasmul from "../../asset/Surrounding/mall.webp";
import binus from "../../asset/Surrounding/swimmingpool.webp";
import breeze from "../../asset/Surrounding/095968600_1521191733-Apa-Saja-Tugas-Dokter-yang-Tidak-Boleh-Dikerjakan-Perawat-By-Dragon-Images-shutterstock.jpg";
import qbig from "../../asset/Surrounding/lrt.webp";
import eka from "../../asset/Surrounding/arf-stasiun-5.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mapsimage from "../../asset/fasilitas.webp";
import mobilemaps from "../../asset/fasilitasm.webp";
const Surrounding = () => {
  const slides = [
    { src: aeon, des: "Universitas" },
    { src: prasmul, des: "Metropolitan Mall" },
    { src: binus, des: "Swimming Pool" },
    { src: breeze, des: "Rumah Sakit" },
    { src: qbig, des: "Stasiun LRT" },
    { src: eka, des: "Stasiun MRT" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="surroundingcontainer">
      <div className="title">SURROUNDING AREA</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="maps-lokasi">
        <img
          id="lokasi"
          className="img-lokasi-dekstop"
          src={mapsimage}
          alt="maps"
        />
        <img
          id="lokasi"
          className="img-lokasi-mobile"
          src={mobilemaps}
          alt="maps"
        />
      </div>
    </div>
  );
};

export default Surrounding;
