import React from "react";
import "./home.scss";
import mobileImage from "../../asset/mobile.png";
import desktopImage from "../../asset/image.webp";
import Navbar from "../../section/navbar/navbar";
import Hero from "../../section/hero/hero";
import About from "../../section/about/about.jsx";
import Promo from "../../section/promo/promo.jsx";
import Benefit from "../../section/benefit/benefit.jsx";
import Produk from "../../section/produk/produk.jsx";
import Surrounding from "../../component/surrounding/Surrounding.jsx";
import Footer from "../../section/footer/footer.jsx";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Hero mobileImage={mobileImage} desktopImage={desktopImage} />
      <About />
      <Benefit />
      <Promo />
      <Produk />
      <Surrounding />
      <Footer />
    </div>
  );
};

export default home;
