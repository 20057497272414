import React from "react";
import "./produk.scss";
import Cardrumah from "../../component/cardrumah/cardrumah";
import prdk from "./produk.json";
import tipe6 from "../../asset/produk/tipe6";
import tipe7 from "../../asset/produk/tipe7";
import tipe8 from "../../asset/produk/tipe8";
const imageMap = {
  tipe6,
  tipe7,
  tipe8,
};

const produk = () => {
  return (
    <div id="produk" className="produk">
      <div className="container-produk">
        <div className="header-produk">
          <div className="judul-produk">OUR HOME</div>
          <div className="subjudul-produk">NEW CLUSTER AMETHYST</div>
        </div>
        <div className="cardhome-container">
          {prdk.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default produk;
