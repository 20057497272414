import React from "react";
import "./benefit.scss";
import { SiGooglemaps } from "react-icons/si";
import { GiTakeMyMoney } from "react-icons/gi";
import { PiPark } from "react-icons/pi";
import { FaHandHoldingHeart } from "react-icons/fa";

const benefit = () => {
  return (
    <div className="benefit">
      <div className="container-benefit">
        <div className="header-benefit">
          KEUNTUNGAN MEMPUNYAI RUMAH DI FAMILIA URBAN BEKASI
        </div>
        <div className="containerlist-benefit">
          <div className="benefita">
            <SiGooglemaps size="50px" />
            <div className="judul">LOKASI STRTATEGIS</div>
          </div>
          <div className="benefitb">
            <GiTakeMyMoney size="50px" />
            <div className="judul">CICILAN RINGAN</div>
          </div>
          <div className="benefitc">
            <PiPark size="50px" />
            <div className="judul">FASILITAS LENGKAP</div>
          </div>
          <div className="benefitd">
            <FaHandHoldingHeart size="50px" />
            <div className="judul">PROSES MUDAH</div>
          </div>
        </div>
        <div className="hc">TANPA DP, CICILAN 6 JUTA/BULAN</div>
      </div>
    </div>
  );
};

export default benefit;
